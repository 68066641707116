import {
  ISliderCarousel as ISliderCarouselComponent,
  SliderCarousel as SliderCarouselComponent,
} from 'components/SliderCarousel';
import type { HeroCarouselComponent } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

interface ISliderCarousel {
  templateProps: HeroCarouselComponent;
}

export const SliderCarousel = ({
  templateProps,
  customStyle,
  watchVideoLabel,
  isRecipeBanner,
  recipeTipsTitle,
  dotsOnTop,
  isOverflowHidden,
  pageType,
  primarySubBrandColor,
  type,
  setDark,
  isForBanners,
  video,
  videoData,
  tips,
  theme,
}: ISliderCarousel & ISharedTemplateProps & ISliderCarouselComponent) => {
  return (
    <SliderCarouselComponent
      bannerMasterbrands={templateProps.bannerMasterbrands}
      banners={templateProps.banners}
      customStyle={customStyle}
      watchVideoLabel={watchVideoLabel}
      isRecipeBanner={isRecipeBanner}
      recipeTipsTitle={recipeTipsTitle}
      dotsOnTop={dotsOnTop}
      isOverflowHidden={isOverflowHidden}
      pageType={pageType}
      primarySubBrandColor={primarySubBrandColor}
      type={type}
      setDark={setDark}
      isForBanners={isForBanners}
      video={video}
      videoData={videoData}
      tips={tips}
      theme={theme}
    />
  );
};
